import { TradingAccount as RawTradingAccount } from '@fsd/shared/api';
import { ROUTES } from '@fsd/shared/constants/routes';
import { TradingAccount as MappedTradingAccount, WEB_TERMINAL_PLATFORMS } from '../../types';

export const tradingAccountMapper = (tradingAccount: RawTradingAccount): MappedTradingAccount => {
  const {
    id,
    attributes: {
      login,
      number,
      type,
      platform: {
        name: platformName,
        humanName: platformHumanName,
      },
    },
  } = tradingAccount;

  const webTerminal = {
    name: ROUTES.MT_WEB.NAME,
    params: {
      type: WEB_TERMINAL_PLATFORMS[platformName],
    },
    query: {
      login,
    },
  };

  return {
    id,
    login,
    number,
    type,
    platformName,
    platformHumanName,
    webTerminal,
  };
};
