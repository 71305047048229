import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useAccountApi, type NumberOfAccounts } from '@fsd/shared/api';
import { mappersLib } from '../lib';
import { TradingAccount } from '../types';

export const useUserTradingAccounts = defineStore('trading-accounts', () => {
  const tradingAccounts = ref<TradingAccount[]>([]);
  const numberOfAccounts = ref<NumberOfAccounts>({
    numberOfActive: 0,
    numberOfArchived: 0,
  });
  const { getTradingAccounts, getNumberOfAccounts } = useAccountApi();

  const fetchTradingAccounts = async () => {
    const { data } = await getTradingAccounts();

    tradingAccounts.value = data.map(mappersLib.tradingAccountMapper);
  };

  const fetchNumberOfAccounts = async () => {
    const data = await getNumberOfAccounts();
    numberOfAccounts.value = data;
  };

  return {
    tradingAccounts,
    numberOfAccounts,
    fetchTradingAccounts,
    fetchNumberOfAccounts,
  };
});
