import {
  TRADING_ACCOUNT_TYPES,
  TRADING_ACCOUNT_PLATFORM_NAMES,
} from '@fsd/shared/api';

const TERMINAL_PLATFORMS = {
  MT4: 'metaTrader4',
  MT5: 'metaTrader5',
  MT5_DEMO: 'metaTrader5Demo',
} as const;

export const WEB_TERMINAL_PLATFORMS = {
  [TRADING_ACCOUNT_PLATFORM_NAMES.MT4]: TERMINAL_PLATFORMS.MT4,
  [TRADING_ACCOUNT_PLATFORM_NAMES.MT5]: TERMINAL_PLATFORMS.MT5,
  [TRADING_ACCOUNT_PLATFORM_NAMES.MT5_DEMO]: TERMINAL_PLATFORMS.MT5_DEMO,
};

export interface TradingAccount {
  id: string,
  login: number,
  number: string,
  type: typeof TRADING_ACCOUNT_TYPES[keyof typeof TRADING_ACCOUNT_TYPES],
  platformHumanName: string,
  platformName: typeof TRADING_ACCOUNT_PLATFORM_NAMES[keyof typeof TRADING_ACCOUNT_PLATFORM_NAMES],
  webTerminal: {
    name: string,
    params: {
      type: typeof TERMINAL_PLATFORMS[keyof typeof TERMINAL_PLATFORMS],
    },
    query: {
      login: number,
    },
  },
}
