export const TRADING_ACCOUNT_TYPES = {
  DEMO: 'Demo',
  ISLAMIC: 'Islamic',
  STANDARD: 'Standard',
  ECN: 'ECN',
  PREMIUM: 'Premium',
  STRATEGY: 'Strategy',
  FIXED: 'Fixed',
  CRYPTO: 'Crypto',
  ZERO: 'Zero',
  MASTER: 'Master', // in figma it calls 'payment account'
  MASTER_DISPLAY_NAME: 'Copy Trading',
  INVEST: 'Invest',
  XXX: 'XXX',
  BONUS: 'Bonus',
  COPY_TRADING: 'Copy Trading',
} as const;

export const TRADING_ACCOUNT_PLATFORM_NAMES = {
  MT4: 'mt',
  MT5: 'mt5',
  MT5_DEMO: 'mt5Demo',
} as const;

export interface TradingAccount {
  type: string,
  id: string,
  attributes: {
    number: string,
    login: number,
    createdAt: string,
    balance: {
      amount: string,
      currency: string
    },
    internalId: number,
    type: typeof TRADING_ACCOUNT_TYPES[keyof typeof TRADING_ACCOUNT_TYPES],
    accountType: string,
    leverage: number,
    platform: {
      name: typeof TRADING_ACCOUNT_PLATFORM_NAMES[keyof typeof TRADING_ACCOUNT_PLATFORM_NAMES],
      humanName: string
    },
    server: {
      name: string
    },
    isIslamic: boolean,
    isEcnPromo: boolean,
    allowedIslamic: boolean,
    equity: number,
    equityInUsd: number,
    bonus: number,
    groupName: string,
    accountGroupRequestIsActive: boolean
  },
  links: {
    self: string
  }
}

export interface GetTradingAccountsResponse {
  data: TradingAccount[]
}

export enum RequestAccountLeverageStatus {
  Accepted = 'accepted',
  Requested = 'requested',
}

export interface AccountWithLeverage {
  type: string;
  id: string;
  attributes: {
    uuid: string;
    login: number;
    availableLeverages: number[];
  };
}

export interface GetAccountsLeveragesResponse {
  data: AccountWithLeverage[];
}

export interface PostAccountLeverageResponse {
  data: {
    type: string,
    id: number,
    attributes: {
      id: number,
      status: RequestAccountLeverageStatus,
      current_leverage: number,
      requested_leverage: number,
      execution_type: string,
      comment: string,
      account: {
        login: number,
        uuid: string
      },
      user: {
        uuid: string
      },
      created_at: string,
      updated_at: string
    },
  }
}

export interface PatchAccountPassword {
  data: {
    attributes: {
      password: string;
    },
  }
}

export interface NumberOfAccounts {
  numberOfActive: number;
  numberOfArchived: number;
}

export interface GetNumberOfAccountsResponse {
  data: {
    attributes: NumberOfAccounts;
  }
}
