import { Action } from '@amarkets/atlas/esm/lib/action';
import { useUserTradingAccounts } from '@fsd/entities/user-trading-accounts';
import {
  MUTATION_SET_ACCOUNTS_DATA,
  MUTATION_SET_ACCOUNTS_DATA_LOADING,
} from '../types';

export class FetchAccountsNumber extends Action {
  _api;

  constructor({ accountApi }) {
    super();
    this._api = accountApi;
  }

  // eslint-disable-next-line class-methods-use-this
  async execute({ commit }) {
    await FetchAccountsNumber._withLoading(async () => {
      try {
        const userTradingAccounts = useUserTradingAccounts();
        await userTradingAccounts.fetchNumberOfAccounts();

        commit(MUTATION_SET_ACCOUNTS_DATA, userTradingAccounts.numberOfAccounts);
      } catch (e) {
        console.error(e, 'while execute FetchAccountsNumber');
      }
    }, commit, MUTATION_SET_ACCOUNTS_DATA_LOADING);
  }
}
